<template>
  <v-row justify="center">
    <v-dialog
      persistent
      v-model="contractDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="toogleContractDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Contratos</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <!-- v-if="contractNotSaved" -->
            <v-btn dark text @click="saveContract"> Salvar </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="mx-8 mt-5">
          <v-tabs
            id="companyFormTab"
            v-model="tab"
            style="border-bottom: 1px solid #eee"
          >
            <v-tab> Dados do contrato </v-tab>
            <v-tab :disabled="paymentCondition"> Definições de Cobrança </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <ContractForm
                :getPaymentsData="getPaymentsData"
                ref="contractDataForm"
                :paymentCondition="paymentCondition"
                :contractDialog="contractDialog"
                :editContractData="editContractData"
                :paymentConditionOpen="paymentConditionOpen"
              />
            </v-tab-item>
            <v-tab-item :disabled="paymentCondition">
              <BillingBillets
                ref="billingBilletDataForm"
                :contractDialog="contractDialog"
                :editContractData="editContractData"
                :paymentConditionOpen="paymentConditionOpen"
              />
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import ContractForm from "./ContractForm.vue";
import BillingBillets from "./BillingBillets.vue";
// import PaymentDefinitions from "./PaymentDefinitions.vue";
import CardInfo from "../common/CardInfo.vue";
import MyFilters from "../../filters/genericFilters";
export default {
  watch: {
    contractDialog(v) {
      this.tab = 1;
      setTimeout(() => {
        this.tab = 0;
      }, 5);
      if (!v) {
        this.paymentCondition = true;
        //this.tab = 0;
        this.contractNotSaved = true;
      } else {
        //this.tab = 0;
        if (this.editContractData) {
          this.contractNotSaved = false;
        }
      }
    },
  },
  data() {
    return { tab: null, contractNotSaved: true, paymentCondition: true };
  },
  methods: {
    getPaymentsData() {
      this.$refs.billingBilletDataForm.getConfiguredBanks();
      this.$refs.billingBilletDataForm.getProfiles();
    },
    paymentConditionOpen() {
      console.log("open2");
      this.paymentCondition = !this.$refs.contractDataForm.verifyServicePrefix(
        this.$refs.contractDataForm.formData.service_id
      );
    },
    saveContract() {
      let payment_condition = null;
      if (
        this.$refs.contractDataForm.verifyServicePrefix(
          this.$refs.contractDataForm.formData.service_id
        )
      ) {
        if (
          !this.$refs.billingBilletDataForm.$refs.billingBilletForm.validate()
        ) {
          this.tab = 1;
          return;
        }
        payment_condition = { ...this.$refs.billingBilletDataForm.formData };
      }
      if (!this.$refs.contractDataForm.$refs.contractForm.validate()) {
        this.tab = 0;
        return;
      }
      let query = { ...this.$refs.contractDataForm.formData };
      if (payment_condition) {
        query.payment_condition = { ...payment_condition };
      } else {
        query.payment_condition = null;
      }
      // console.log('ddd')
      // return
      let url = query.id
        ? "/api/contract/paymentCondition/update"
        : "/api/contract/create";
      let objEdition = null;
      if (query.id) {
        if (query.service.prefix == "dtvgo") {
          objEdition = {};
          objEdition.service_id = query.service_id;
          objEdition.id = query.id;
          objEdition.in_abranet = query.in_abranet;
          url = "/api/contract/update";
        } else {
          query = { ...payment_condition };
          url = "/api/contract/paymentCondition/update";
        }
      } else {
        url = "/api/contract/create";
      }
      // if (query.id) {
      //   query = null
      //   query = { ...payment_condition }
      // }
      var obj = {
        url: url,
        query: objEdition ? objEdition : query,
        method: "post",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        //console.log(response);
        if (
          response.data.bank_slips_transaction &&
          response.data.bank_slips_transaction.length
        ) {
          this.updateBankSlipData(response.data.bank_slips_transaction[0]);
        }
        if (
          response.data.pix_transaction &&
          response.data.pix_transaction.length
        ) {
          this.getQrCode(response.data.pix_transaction[0].id);
        }
        this.contractNotSaved = false;
        this.toogleContractDialog();
        this.$refs.contractDataForm.formData = { ...response.data };
        this.getContracts("nLoad");
        this.$store.commit("showAlert", {
          message: "Contrato salvo com sucesso",
          type: "success",
        });
      });
    },
  },
  props: {
    getContracts: Function,
    toogleContractDialog: Function,
    contractDialog: Boolean,
    editContractData: Object,
    bankSlipData: Object,
    updateBankSlipData: Function,
    qrCodeData: Object,
    getQrCode: Function,
  },
  components: {
    ContractForm,
    BillingBillets,
    // PaymentDefinitions,
    CardInfo,
  },
};
</script>

<style>
.chipFly {
  position: absolute;
  right: 0;
}
</style>